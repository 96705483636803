import { mapHelper } from 'common/utils'
// 单据类型状态:是否启用
const billTypeStatus = [
    {
        text: '停用',
        value: 0
    },
    {
        text: '启用',
        value: 1
    }
]

const {
    map: billTypeMap,
    setOps: billTypeOps
} = mapHelper.setMap(billTypeStatus)

export {
    billTypeMap,
    billTypeOps
}