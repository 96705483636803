<template>
  <div class="BillTypeList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-input label="单据编码" v-model="searchParams.billtypeCode" />
        <v-input label="单据类型" v-model="searchParams.billtypeName" />
        <v-select label="单据状态" v-model="searchParams.isEnable" :options="billTypeOps" />
        <!-- <v-datepicker label="创建时间"
                      :startTime.sync="searchParams.intimeStart"
                      :endTime.sync="searchParams.intimeEnd"></v-datepicker> -->
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)" />
        <v-button text="删除" type="text" permission="delete" @click="handleDelete(scope.row)" />
      </template>
      <!-- <template #batchSlot="scope">
        <v-button text="批量删除"
                  permission="delete"
                  :disabled="scope.selectedData.ids.length === 0"
                  @click="batch(scope.selectedData)" />
      </template> -->
    </list>
  </div>
</template>

<script>
import { getBillTypeListURL, deleteURL, batchDeleteURL } from './api'
import { billTypeMap, billTypeOps } from './map'
import { ButtonTree, SelectTree } from '@/components/bussiness'
// import { ButtonTree } from 'components/bussiness/index'


export default {
  name: 'billType',
  data () {
    return {
      searchUrl: getBillTypeListURL,
      maxDate: new Date(),
      billTypeOps:billTypeOps(1),
      searchParams: {
          billtypeCode: '',
          billtypeName: '',
          isEnable: undefined,
          orgId: undefined
      },
      headers: [
        {
          prop: 'billtypeCode',
          label: '单据编码'
        },
        {
          prop: 'billtypeName',
          label: '单据名称'
        },
        {
          prop: 'isEnable',
          label: '状态',
          formatter (row) {
            return billTypeMap[row.isEnable]
          }
        },
        {
          prop: 'inTime',
          label: '创建时间'
        },
        {
          prop: 'inUserName',
          label: '创建人'
        }
      ]
    }
  },
  computed: {
    visible () {
      const userType = this.$store.state.userInfo.userType
      return userType === '106'
    },
    userInfo () {
      return this.$store.state.userInfo
    },
    menuPermission () {
      return this.$store.state.menuStore.menuPermission
    },
  },

  methods: {
    create () {
      if (this.visible) {
        let orgId = this.currentNodeKey
        this.$router.push({
          name: 'billTypeForm'
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    edit (row) {
      if (this.visible) {
        this.$router.push({
          name: 'billTypeForm',
          query: {
            id: row.id
          }
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    async handleDelete (row) {
        const result = await this.$confirm('确认删除？', {
            title: '提示'
        })
        if(result) {
            var that = this
            let params = {
                id: row.id
            }
            that.$axios.get(deleteURL, { params: params }).then((res) => {
                if (res.status === 100) {
                    that.$message.success("删除成功")
                    that.$refs.list.searchData()
                } else {
                    // that.$message.warning(res.msg)
                }
            })
        }
       
    },
    async batch (selected) {
      let result = await this.$confirm('确认删除？', {
        title: '提示'
      })
      if (result) {
        // 操作日志添加操作对象参数,组装规则：敏感词-分类
        console.log(selected.ids)
        const params = {
          ids: selected.ids.join(',')
        }
        const { status, data } = await this.$axios.get(batchDeleteURL, { params })
        if (status === 100) {
          this.$message.success('删除成功')
          this.$refs.list.searchData()
        }
      }
    },

  }
}
</script>
<style lang="scss" scoped>
.BillTypeList-wrapper {
  .table-left {
    height: 100%;
    display: inline-block;
    position: relative;
    .tree-search {
      text-align: center;
      ::v-deep .v-control {
        width: 100%;
        .el-input {
          width: 100% !important;
          .el-input__inner {
            outline: none;
            padding: 2px 10px 2px 30px;
            height: 33px;
          }
          .el-input__icon {
            line-height: 33px;
          }
          .el-input__suffix {
            line-height: 33px;
          }
        }
      }
    }
    ::v-deep .el-loading-mask {
      z-index: 100!important;
    }
    .tree-select {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      top: 38px;
      left: 0;
      display: flex;
      z-index: 200;
      .select-button {
        width: 100%;
        // height: 100%;
        flex: 1;
        ::v-deep .el-button {
          padding: 0 8px;
          text-align: left;
          color: #414142;
          background-color: #e3e5e9;
          border-color: #e8e9eb;
          i {
            float: right;
          }
        }
      }
      .select {
        ::v-deep .el-button {
          background: linear-gradient(90deg, #43a8ff 0%, #529fff 99%);
          color: #fff;
        }
      }
      .add-button {
        margin-left: 8px;
        ::v-deep .el-button{
          padding: 0 8px;
        }
      }
      .tree-select__list {
        position: absolute;
        top: 30px;
        left: 0;
        text-align: left;
        display: inline-block;
        width: 100%;
        border: 1px solid #e4e7ed;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        z-index: 200;
        > li {
          cursor: pointer;
          padding: 0px 12px;
          color: #909399;
          &:hover {
            color: #1B8CF2;
            background-color: #f5f7fa;
          }
        }
      }
    }
    .tree-group {
      margin-top: 40px;
      height: calc(100% - 73px);
      width: 240px;
    }
    .treeSwith {
      cursor: pointer;
      display: inline-block;
      position: relative;
      height: 100%;
      vertical-align: top;
      width: 36px;
      img {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 36px;
        height: 80px;
      }
      &-bar {
        width: 8px;
        height: 160px;
        background: linear-gradient(
          to top left,
          rgba(228, 228, 228, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
  .dialog-tree__node {
    text-align: left;
    .p-text {
      margin-top: 0;
    }
    .p-mar-0 {
      margin: 0;
    }
    .p-pad-l {
      padding-left: 45px;
    }
    .el-form-item:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
